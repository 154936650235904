<template>
  <div id="app" data-app>
        <nav id="nav">
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/#/gallery">Gallery</a></li>
      </ul>
    </nav>
    <router-view></router-view>
    <footer style="background-color: black;"> 
      <br>
      <small style="color: white;">&copy; Copyright {{currentYear}}, ohsojazz.com. All Rights Reserved.<img src="../public/favicon_white.png" height="20"></small> 
      <br>
      <small>
        <span style="color: white;">
            Title photo by 
            <a href="https://unsplash.com/@mitifotos?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" style="color: white">Miti</a> 
            on 
            <a href="https://unsplash.com/s/photos/jazz?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" style="color: white">Unsplash</a>
        </span>
        <br><br>
      </small>
    </footer> 
  </div>
  
</template>

<script>
var d = new Date();

export default {
  name: 'App',
  data () {
    return {currentYear: d.getFullYear()}
  }
};
</script>

<style>
  @import './css/style.css';

#nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		padding: 1.5em 0 1.5em 0;
		z-index: 1;
    overflow: hidden;
    
  background: rgba(0, 0, 0);
  color: #fff;
  display: inline-block;
  text-align: center;
	}

		#nav > ul {
			line-height: 0px;
			position: relative;
			display: inline-block;
			margin: 0;
			height: 21px;
			border-left: solid 1px rgba(192, 192, 192, 0.35);
			border-right: solid 1px rgba(192, 192, 192, 0.35);
		}

			#nav > ul:before, #nav > ul:after {
				content: '';
				display: block;
				width: 300%;
				position: absolute;
				top: 50%;
				margin-top: -2px;
				height: 5px;
				border-top: solid 1px rgba(192, 192, 192, 0.35);
				/* border-bottom: solid 1px rgba(192, 192, 192, 0.35); */
			}

			#nav > ul:before {
				left: 100%;
				margin-left: 1px;
			}

			#nav > ul:after {
				right: 100%;
				margin-right: 1px;
			}

			#nav > ul > li {
				display: inline-block;
				margin: -9px 0.5em 0 0.5em;
				border-radius: 0.5em;
				padding: 0.85em;
				border: solid 1px transparent;
				-moz-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
				-webkit-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
				-ms-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
				transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
			}

				#nav > ul > li.active {
					border-color: rgba(192, 192, 192, 0.35);
				}

				#nav > ul > li > a, #nav > ul > li > span {
					display: block;
					color: inherit;
					text-decoration: none;
					border: 0;
					outline: 0;
				}

				#nav > ul > li > ul {
					display: none;
				}
</style>
