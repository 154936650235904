<template>
  <div id="gallery">
    <div id="header"> 
      <div id="inner" style="margin-left: auto; margin-right: auto; max-width: 50%;">
        <h1>Gallery</h1>
      </div>
    </div>
    
    <div style="margin-left: auto; margin-right: auto; margin-top: 50px; margin-bottom: 100px;">
      <iframe id=videoTag style="display: block; margin-left: auto; margin-right: auto;" width="560" height="315" src="https://www.youtube.com/embed/MrUibWRoc2M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
      <img id=pngTag src="" style="display: none; margin-left: auto; margin-right: auto; margin-top: 10px; width: 40%; height: 40%;">
    </div>

    <hooper :itemsToShow="1.5" :centerMode="true" :progress="true" :infiniteScroll="true" 
      style="width: 70%; height: 100%; margin-bottom: 50px; margin-left: auto; margin-right: auto;  ">
      <slide>
        <div id=thumbnailContainer @click="showVideo('https://www.youtube.com/embed/MrUibWRoc2M')" style="cursor: pointer">
            <img src="../assets/thumbnails/song1.png" id=thumbnail>
        </div>
      </slide>
      <slide>
        <div id=thumbnailContainer @click="showPng('/ohsojazz-All major chords.png')" style="cursor: pointer">
          <img src="../assets/thumbnails/all_major_chords.png" id=thumbnail>
        </div>
      </slide>
      <slide>
        <div id=thumbnailContainer @click="showPng('/ohsojazz-All minor chords.png')" style="cursor: pointer">
          <img src="../assets/thumbnails/all_minor_chords.png" id=thumbnail>
        </div>
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>



  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  created() {
    this.$gtag.event('open_gallery', { method: 'Google' })
  },
  name: 'home',
    components: {
          Hooper,
    Slide,
        HooperNavigation

  },

  methods: {
    showVideo: function(url) {
      let videoTag = document.getElementById('videoTag');
      let pngTag = document.getElementById('pngTag');
      pngTag.style.display = 'none';
      videoTag.style.display = 'block';
      videoTag.src = url;
      videoTag.scrollIntoView({behavior: 'smooth'});
    },
    showPng: function(url) {
      let videoTag = document.getElementById('videoTag');
      let pngTag = document.getElementById('pngTag');
      pngTag.style.display = 'block';
      videoTag.style.display = 'none';
      pngTag.src = url;
      pngTag.scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>

<style> 

svg.icon.icon-arrowRight {   
  background-color: white;
  width: 50px;
  height: 50px;
  border: 1px solid black;
  border-radius: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,1);

} 
svg.icon.icon-arrowLeft {   
  background-color: white;
  width: 50px;
  height: 50px;
  border: 1px solid black;
  border-radius: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,1);
} 

#thumbnail {
  margin-top: 10px; 
  margin-bottom: 10px; 
  max-width: 60%; 
  max-height: 60%;
}

#thumbnailContainer {
  margin-top: 10px; 
  margin-bottom: 10px;  
  margin-left: 10px;  
  margin-right: 10px;  
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}

iframe {
    max-width:  80% !important;
    max-height: 80% !important;
}
 </style>