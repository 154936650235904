<template>
  <div>
    <v-container fill-height id="header" style="max-width: 100%; max-height:100%;">
      <v-row align="center" justify="center">
          <!-- <v-col id="inner" cols="12" md="12" style="margin-top: 60px; margin-bottom: 60px; "> -->
          <div style="margin-left: auto; margin-right: auto; margin-top: 60px; margin-bottom: 60px; ">
            <h1>Oh so Jazz</h1>
            <h4>
              Love Jazz and want to be able to play it yourself? <br>Interested in learning how to improvise?
            </h4><br>
            <h4>We hear you. Tell us your chords, and we tell you which scales are best. <br>Play any melody over these scales and start improvising today.</h4>
          </div>
          <!-- </v-col> -->
      </v-row>
    </v-container>

    <v-container style="margin-left: auto; margin-right: auto; margin-top: 100px; max-width: 80%; max-height:10%;">
      <v-row style="max-height: 100%;" >
        <v-col cols="12" md="4">
          <div >
          <img src="../assets/chordstacks_1.png" height="40"></div>
          <h2>Choose a song</h2>
          <p>Ask a buddy to play a background track for you, or look for background tracks on youtube.</p>
          <img src="../assets/youtube.png" style="margin-top: 10px; max-width: 100%; max-height: 50%;">
        </v-col>
        <v-col cols="12" md="4">
          <img src="../assets/chordstacks_2.png" height="40">
          <h2>Enter the chord progression</h2>
          <p>Find out what chords are being played in your chosen song and enter them below.</p>
          <img src="../assets/input.png" style="max-width: 100%; max-height: 50%;">
        </v-col>
        <v-col cols="12" md="4">
          <img src="../assets/chordstacks_3.png" height="40">
          <h2>Start improvising</h2>
          <p>We give you the best scales to play for each chord. Improvise any melody you can imagine.</p>
          <img src="../assets/favourite_song.png" style="max-width: 100%; max-height: 50%;">
        </v-col>
      </v-row>
    </v-container>
    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline">
  
      <!-- Optional -->
      <div slot="postponeContent">
          &times;
      </div>
  
      <!-- Optional -->
      <div slot="message">
          We use cookies to ensure you get the best experience on our website. <a href="https://cookiesandyou.com/" target="_blank">Learn More...</a>
      </div>
  
      <!-- Optional -->
      <div slot="declineContent">
        OPT OUT
      </div>
  
      <!-- Optional -->
      <div slot="acceptContent">
          GOT IT!
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import '../css/style.css';
var chords = require('./chords');

export default {
  name: 'HelloWorld',
  data() {
    return {
      title: '',
      link: '',
      rules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 100 || 'Max 100 characters',
        value => {

          let theArray = value.trim().split(/\s+/);
          for (var j = 0; j < theArray.length; j++){
            if (!this.gold.includes(theArray[j].toLowerCase())) {
              this.invalid = true;
              return "Chord '" + theArray[j] + "' not currently supported."
            }
          }
          this.invalid = false;
          return true
        },
      ],
      content: '',
      gold: chords,
      invalid: true
    }
  },
  methods: {
    cookieStatus (status) {
      console.log('status: ' + status)
      this.status = status
    },
    cookieClickedAccept () {
      console.log('here in accept')
      this.status = 'accept'
    },
    cookieClickedDecline () {
      console.log('here in decline')
      this.status = 'decline'
    },
    cookieRemovedCookie () {
      console.log('here in cookieRemoved')
      this.status = null
      this.$refs.myPanel1.init()
    },

    removeCookie () {
      console.log('Cookie removed')
      this.$refs.myPanel1.removeCookie()
    }
  }
}
</script>

<style scoped>


#inner {
  position: relative;
  background: rgba(0, 0, 0, 0.689);
  color: #fff;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  display: inline-block;
  padding: 3em;
  text-align: center;
}


</style>
