import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import router from './router'

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

let track_id = "G-JR23F6VW44";

Vue.use(VueGtag, {
  config: { id: track_id, send_page_view: false}
});

Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
