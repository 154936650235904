<template>
  <div id="home">
    <HelloWorld/>
    <GettingStarted/>
  </div>
</template>

<script>
import HelloWorld from './HelloWorld.vue'
import GettingStarted from './GettingStarted.vue'

export default {
  name: 'home',
    components: {
    HelloWorld,
    GettingStarted,
  },
}
</script>

<style>
</style>