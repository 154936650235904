<template>
  <div>    

    <div id="background" style="margin-top: 60px; margin-bottom: 60px">
      <div id="inner" style=" max-width: 100%;">
        <h1>Get started</h1>
      </div>
    </div>

    <v-row style="margin-left: auto; margin-right: auto;  max-width: 70%; max-height: 70%;">
        <v-textarea
          id="content"
          v-model="content"
          name="input-1"
          label="Enter a chord progression (e.g. Fm7 Bb7 Ebmaj7)"
          solo
          :rules="rules"
          rows="3"
          style="width:10px;"
        ></v-textarea>
    </v-row>
    <v-row style=" margin-left: auto; margin-right: auto;  max-width: 70%;" >
      <v-col cols="12" lg="6">
            <v-text-field
            v-model="title"
            label="Song title (optional)"
          ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
          <v-text-field
            v-model="link"
            label="Link to song/video (optional)"
          ></v-text-field>
      </v-col>
    </v-row>
    <v-row style=" margin-left: auto; margin-right: auto;  max-width: 70%;" >
      <v-col cols="12" lg="4">
            <v-select
              v-model="selectChords"
              :items="outputChordsItems"
              label="Chords"
            ></v-select>
      </v-col>
      <v-col cols="12" lg="4">
            <v-select
              v-model="selectScales"
              :items="outputScalesItems"
              label="Scales"
            ></v-select>
      </v-col>
      <v-col cols="12" lg="4">
            <v-select
              v-model="selectFormat"
              :items="formatItems"
              label="Format"
            ></v-select>
      </v-col>
    </v-row>

    <v-btn
      class="mr-4"
      type="submit"
      :disabled="invalid"
      @click="submit"
      :loading="loading"
    >submit</v-btn>
    <v-btn @click="clear">clear</v-btn>

  <div class="scrolling-wrapper-flexbox" id="previewBox" style="margin-top: 30px; margin-left: auto; margin-right: auto; max-width: 80%;">

    <div class="card"></div>
    <div class="card"></div>
    <div class="card"></div>
    <div class="card"></div>
    <div class="card"></div>
    <div class="card"></div>

  </div>

    <!-- <div id="background" style="margin-top: 60px; margin-bottom: 60px">
      <div id="inner" style=" max-width: 100%;">
        <h1>Get in touch</h1>
      </div>
    </div> -->

    <div style="margin-top: 80; margin-bottom: 80px"><img src="favicon.jpg" height="20"></div>
    <div id="contact" class="p-3" style="margin-bottom: 30px; margin-left: auto; margin-right: auto; max-width: 60%;">
      <div class="row justify-content-center ">
      
        <div v-if="show_contact == true" class="col-lg-8">
          <h4>For feedback, bug reports or feature requests please fill out the information below.</h4>
          
          <div v-if="contact_notice != ''" class="alert alert-warning">
            There was a problem submitting our message. {{contact_notice}}
          </div>

          <form @submit.prevent="sendContactMessage()">
            <div class="form-group text-left">
              <input v-model="contact_email"
                    type="email"
                    class="form-control"
                    placeholder="Enter Your Email"
                    >
              <textarea v-model="contact_message"
                        class="form-control mt-3"
                        placeholder="Enter Your Message"
                        rows="5"
                        ></textarea>
            </div>
            <v-btn type="submit" class="mr-4">Send Message</v-btn>
          </form>
        </div>

        <div v-else>
          <h4>Message Sent Successfully!</h4>
          <p>Thank you for contacting us, we'll get back to you as soon as we can.</p>
        </div>
      </div>
    </div>
  
  </div>


</template>

<script>
import '../css/style.css';
var chords = require('./chords');

export default {
  name: 'GettingStarted',
  data() {
    return {
      title: '',
      link: '',
      rules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 100 || 'Max 100 characters',
        value => {

          let theArray = value.trim().split(/\s+/);
          for (var j = 0; j < theArray.length; j++){
            if (!this.gold.includes(theArray[j].toLowerCase())) {
              this.invalid = true;
              return "Chord '" + theArray[j] + "' not currently supported."
            }
          }
          this.invalid = false;
          return true
        },
      ],
      outputChordsItems: ['Yes', 'No'],
      outputScalesItems: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      formatItems: ["A3", "A4", "A5", "A6"],
      content: '',
      gold: chords,
      invalid: true,
      loading: false,
      contact_email: '',
      contact_message: '',
      contact_notice: '',
      show_contact: true,
      selectChords: "Yes",
      selectScales: 4,
      selectFormat: "A4"

    }
  },
  methods: {

    clearSheets () {
      let previewBox = document.getElementById('previewBox');
      var numCards = previewBox.children.length;
      for (var c = 0; c < numCards; c++) {
        var child = previewBox.children[c];
        var numImages = child.children.length;
        if (numImages > 0) {
          for (var imgIter = 0; imgIter < numImages; imgIter++) {
            var childToRemove = child.children[0]
            child.removeChild(childToRemove);
          }    
        }  
      }
      document.getElementById("previewBox").style.boxShadow = "none";
    },

    clear () {
        this.title = ''
        this.link = ''
        this.content = ''
        this.clearSheets();
        this.invalid = true;
        },
    // test: function() {
    //    var newTab = window.open();
    //      var data = document.getElementById("choicedPhoto").getAttribute("src");
    //      setTimeout(function () {
    //          newTab.document.body.innerHTML = "<img src='" + data + "'>";
    //      }, 500);
    //      return false;
    // },
    base64toBlob: function(base64Data, contentType) {
      contentType = contentType || '';
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          var begin = sliceIndex * sliceSize;
          var end = Math.min(begin + sliceSize, bytesLength);

          var bytes = new Array(end - begin);
          for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
              bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
          }
        return new Blob(byteArrays, { type: contentType });
      },

    dataURItoBlob: function (dataURI) {
      var byteString;
      byteString = atob(dataURI);
      var mimeString = "img/png"
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
        }
      return new Blob([ia], {type:mimeString});
    },

    submit: async function() {
      this.loading = true;
      this.clearSheets();
      let previewBox = document.getElementById('previewBox');

      this.$gtag.event('submit', { method: 'Google' })
      var response = await this.$axios.post("https://ohsojazz-hnctrzkzna-ew.a.run.app/entry", {
      // var response = await this.$axios.post("http://0.0.0.0:8081/entry", {
          request: this.content,
          link: this.link,
          title: this.title,
          output_chords: this.selectChords,
          scales_per_chord: this.selectScales,
          format: this.selectFormat
        });

      var responseLen = Object.keys(response.data).length - 1
      for (var i = 0; i <  responseLen; i++) {
          // const blob = this.base64toBlob(response.data[i], response.headers['content-type'])

          let blob = this.dataURItoBlob(response.data[i])
          let imageURL = URL.createObjectURL(blob)
          var img = document.createElement("img");
          img.src = imageURL;
          img.style = "max-height: 800px; max-width: 800px;"
          let ahref = document.createElement("a")
          ahref.href = imageURL
          ahref.download = `ohsojazz-${this.title}.png`
          ahref.appendChild(img)
          var child = previewBox.children[i];
          child.appendChild(ahref)
      }
      document.getElementById("previewBox").style.boxShadow = "0px 2px 8px 0px rgba(0,0,0,0.1)";
      if (responseLen == 1) {
        previewBox.style.maxWidth = "800px"
      }
      else {
        previewBox.style.maxWidth = "80%"
      }
      this.loading = false;
    },

    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    sendContactMessage: function() {
      if (!this.validEmail(this.contact_email)) {
        this.contact_notice = "Please enter a valid email address."

      } else if (this.contact_message.length < 10) {
        this.contact_notice = "Your message is too short."
      } else {
        const url = `https://us-central1-ohsojazz-18843.cloudfunctions.net/sendEmail?email_from=${this.contact_email}&message=${this.contact_message}`
        const requestOptions = {
          method: "GET",
          headers: {"Content-Type": "application/json"}
        };
        fetch(url, requestOptions);
        this.show_contact = false;
      }
    }
  }
}
</script>

<style scoped>

 #background {
  background-image: url('../assets/miti-vSws0g1KjxI-unsplash.jpg');
  position: relative;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
  cursor: default;
}

#inner {
  position: relative;
  background: rgba(0, 0, 0, 0.49);
  color: #fff;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  cursor: default;
  padding: 3em;
  text-align: center;
}


.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.card {
  flex: 0 0 auto;
  border-color: white;
  display: table;
  margin: 0 auto;
}
        
</style>
